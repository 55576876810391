
.langButton {
	width: 94px;
	height: 25px;
	font-size: 12px;
	text-align: right;
	padding: 4px 4px;
}
.bigButton {
	width: 46%;
	height: 60px;
	background: white;
	border: 1px solid #dcdcdc;
	display: inline-block;
	cursor: pointer;
	color: #666;
	font-size: 12px;
	font-weight: bold;
	text-align: center;
	padding: 13px 4px;
	text-decoration: none;
}
#bigButton1 {
	border-top-left-radius: 26px;
	border-bottom-left-radius: 26px;
}
#bigButton2 {
	border-top-right-radius: 26px;
	border-bottom-right-radius: 26px;
}
.bigButton:hover {
	background: white;
}
.bigButtonLigt {
	width: 46%;
	height: 50px;
	background: white;
	border: 1px solid #dcdcdc;
	display: inline-block;
	cursor: pointer;
	color: #666;
	font-size: 12px;
	font-weight: bold;
	text-align: center;
	padding: 13px 4px;
	text-decoration: none;
}
#bigButtonLigt1 {
	border-top-left-radius: 26px;
	border-bottom-left-radius: 26px;
}
#bigButtonLigt2 {
	border-top-right-radius: 26px;
	border-bottom-right-radius: 26px;
}
.bigButtonLigt:hover {
	background: white;
}
.ui-timepicker-container {
	z-index: 9999 !important;
}
.form-check {
	margin-top: 8px;
}
h6 {
	line-height: 1.8;
}
.uploadbutton,
.uploadbutton:hover {
	height: 38px;
	background-color: #AAA;
	color: white;
	font-family: "brandon_textmedium" !important;
}
::placeholder {
	color: #000 !important;
}
.page-title__title {
	text-transform: none;
}
.page-title__subtitle {
	margin-top: -16px;
	font-size: 16px;
}
.link {
	cursor: pointer;
	color: #009ACD;
	font-family: "brandon_textmedium" !important;
}
.btn,
.btn--md,
.btn--md.btn--button,
.nav__btn-phone {
	border-radius: 5px;
	text-transform: uppercase;
	font-size: 16px;
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
.cool-form-group {
	border: 1px #DDD solid;
	min-height: 50px;
	margin: 0 0 12px;
	padding: 0;
}
.cool-form-group-outer {
	border: 0 #DDD solid;
	min-height: 50px;
	margin: 0 0 12px;
	padding: 0;
}
.cool-form-group span {
	text-align: left;
}
.login {
	max-width: 620px;
}
h3 {
	line-height: 1.3;
	text-align: center;
	color: #000000;
	max-width: 680px;
	margin: auto;
	text-transform: uppercase;
}
h1,
h2,
h3 {
	font-family: "brandon_textbold" !important;
}
h1,
h6 {
	text-transform: uppercase;
}
#quickstart h3 {
	font-family: "brandon_textblack" !important;
}
.page p {
	text-align: center;
}
.myp {
	color: #888;
}
#documents p {
	text-align: left;
}
.separator {
	margin-top: 20px;
	margin-bottom: 20px;
	background-color: #CCC;
	height: 1px;
}
@media (min-width: 768px) {
	.separator {
		margin-top: 60px;
		margin-bottom: 60px;
	}
}
.btn--sm {
	padding-left: 38px;
	padding-right: 38px;
	width: 100%;
	height: 42px;
}
label {
	color: #333;
	font-size: 16px;
}
.label {
	padding-left: 0 !important;
	font-weight: 600;
}
@media (max-width: 992px) {
	.nav__dropdown-menu {
		background-color: #009ACD;
		position: relative !important;
		right: 8%;
		bottom: 1px;
		padding-left: 6%;
		padding-right: 6%;
		width: 116% !important;
	}
	.nav__dropdown-menu li a,
	.nav__dropdown-menu li a:hover {
		color: #fff !important;
	}
	.nav__dropdown-menu2 {
		background-color: #009ACD;
		position: relative !important;
		right: 8%;
		bottom: 1px;
		padding-left: 6%;
		padding-right: 6%;
		width: 116% !important;
	}
	.nav__dropdown-menu2 li a,
	.nav__dropdown-menu2 li a:hover {
		color: #fff !important;
	}
}
.center {
	margin: 0 auto !important;
}
.offerOptions {
	text-align-last: center;
	text-align: center;
	-ms-text-align-last: center;
	-moz-text-align-last: center;
	-webkit-text-align-last: center;
}
.footerWrapper {
	background-color: #02A4D3;
}
.footer {
	color: #fff8;
	font-size: 12px;
	padding-top: 20px;
	padding-bottom: 20px;
	text-align: justify;
}
.accordion__body {
	padding: 20px;
}
#application .accordion__body {
	border-width: 0 !important;
}
.optin__input {
	border-left: 0 !important;
	border-right: 0 !important;
	border-top: 0 !important;
}
/* Steps */
.steps {
	background-color: #DDD !important;
	height: 60px;
	margin-bottom: 30px;
	font-family: "brandon_textmedium", sans-serif !important;
}
.steps .stepTitle {
	color: #333;
	font-size: 16px;
	font-family: "brandon_textbold", sans-serif !important;
}
.steps .stepTitleAlone {
	color: #333;
	font-size: 16px;
	line-height: 16px;
	font-family: "brandon_textbold", sans-serif !important;
}
.steps .stepTitleAlone span {
	color: #333;
	font-size: 12px;
	font-family: "brandon_textmedium", sans-serif !important;
}
.steps .stepSubtitle {
	color: #333;
	font-size: 14px;
	font-family: "brandon_textbold", sans-serif !important;
}
#how {
	background-color: #009ACD ;
	color: #FFF !important;
}
#how h2,
#how h3,
#how h4,
#how h5,
#how h6,
#how p {
	color: #FFF !important;
}
#how h5 {
	font-family: "brandon_textbold", sans-serif !important;
}
#how p {
	font-family: "brandon_textregular", sans-serif !important;
}
#how a {
	margin: 0 auto;
}

.yseparator {
	height: 8px;
	width: 120px;
	margin-top: 10px;
	margin-bottom: 10px;
	background-color: #02A4D3;
}
#online h3 {
	line-height: 30px;
	margin-left: 0;
}
#online h6 {
	line-height: 8px;
}
#online h5 {
	font-family: "brandon_textbold", sans-serif !important;
}
#online p {
	color: #888;
}
#application h4 {
	text-transform: none;
	text-align: center;
	font-family: "brandon_textbold", sans-serif !important;
}
#application p {
	color: #888;
}
#discounts h5 {
	font-family: "brandon_textregular", sans-serif !important;
}
#discounts h6 {
	line-height: 20px !important;
}
.switches p {
	text-align: left;
}
/* Switches */
/* The switch - the box around the slider */
.switch {
	position: relative;
	display: inline-block;
	width: 60px;
	height: 34px;
	margin-left: 10px;
}
/* Hide default HTML checkbox */
.switch input {
	opacity: 0;
	width: 0;
	height: 0;
}
/* The slider */
.slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #ccc;
	-webkit-transition: 0.4s;
	transition: 0.4s;
}
.slider:before {
	position: absolute;
	content: "";
	height: 26px;
	width: 26px;
	left: 4px;
	bottom: 4px;
	background-color: white;
	-webkit-transition: 0.4s;
	transition: 0.4s;
}
input:checked + .slider {
	background-color: #009ACD;
}
input:focus + .slider {
	box-shadow: 0 0 1px #009ACD;
}
input:checked + .slider:before {
	-webkit-transform: translateX(26px);
	-ms-transform: translateX(26px);
	transform: translateX(26px);
}
/* Rounded sliders */
.slider.round {
	border-radius: 34px;
}
.slider.round:before {
	border-radius: 50%;
}
.offer {
	max-width: 500px;
	margin: 0 auto;
	background-color: #DDD;
	border-radius: 20px;
	padding: 30px;
}
.offer h5 {
	font-size: 20px !important;
}
.offer h6 {
	font-size: 20px !important;
}
.swal2-input[type=number] {
	width: 100% !important;
	max-width: 1000px;
	margin-top: 20px !important;
}
#documents,
#documents h6,
#documents p {
	text-align: center !important;
}
#documents h6 {
	margin-bottom: -3px;
}
#documents p {
	font-size: 14px;
	font-family: "brandon_textregular", sans-serif !important;
}
.footer a {
	color: white !important;
	font-size: 16px;
	line-height: 32px;
	text-transform: uppercase;
	font-family: "brandon_textbold", sans-serif !important;
}
.footerSeparator {
	height: 3px;
	margin-top: 30px;
	margin-bottom: 30px;
	background-color: white;
}
.fa-question-circle {
	padding-left: 5px;
	padding-right: 5px;
	height: 50px;
	padding-top: 17px;
	background-color: #EEE8;
}
.btn--secondary {
	background-color: #AAA;
}
.menuWord {
	display: block;
	position: relative;
}
.accountWord {
	display: block;
	position: relative;
}
.callWord {
	display: block;
	position: relative;
	top: 0;
}
.userIcon i {
	font-size: 23px;
}
.accountWrapper {
	flex-direction: column;
}
#nav__icon-toggle {
	font-family: "Brandon Text";
}
.contact-form label {
	font-size: 14px;
}
.hidden {
	display: none !important;
}
.rc-slider-mark-text {
	width: 66px;
}

/* Agreement */
.signButton {
	font-family: "Brandon Text";
	background-color: #009ACD !important;
	max-width: 350px;
}

#military_1_btn, #military_2_btn, #military_3_btn {
	font-family: "Brandon Text";
	background-color: #009ACD !important;
	border-width: 0 !important;
	max-width: 200px;
	max-height: 50px;
}

#military_1, #military_2, #military_3 {
	border-color: #009ACD !important;
}

#startSigning {
	font-family: "Brandon Text";
	width: 800px !important;
	border-width: 0 !important;
	margin-top: 30px !important;
	margin-bottom: 30px !important;
}

#adopt {
	background-color: #009ACD !important;
	border-width: 0 !important;
}

.wrapper {
	/*padding-left: 100px !important;*/
	/*padding-right: 100px !important;*/
	/*width: 60% !important;*/
}

.esignNotice {
	/*width: 50%;*/
}

#t h1 {
	margin-top: 50px !important;
	margin-bottom: 30px !important;
}

#t {
	/*padding-left: 100px !important;*/
	/*padding-right: 100px !important;*/
	max-width: 90vw !important;
	margin: 0 auto !important;
}

/*-------------------------------------------------------*/
/* Navigation nav__menu
/*-------------------------------------------------------*/
.nav {
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	z-index: 120;
	position: relative;
	/* Dropdowns (large screen) */ }
@media only screen and (min-width: 992px) {
	.nav__holder {
		margin-top: 40px;
		position: relative;} }
.nav__wrap {
	padding-left: 44px; }
@media only screen and (min-width: 992px) {
	.nav__container {
		padding: 0 82px; } }
.nav__menu, .nav__dropdown-menu {
	list-style: none; }
.nav__menu {
	position: relative;
	display: inline-flex;
	align-items: center;
	flex-wrap: wrap; }
.nav__menu li {
	position: relative; }
.nav__menu > li > a {
	font-family: "Roboto", sans-serif;
	font-weight: 700;
	color: #333333;
	font-size: 14px;
	padding: 0 8px;
	line-height: 20px;
	display: block;
	position: relative;
	border-right: 1px solid #d2f4f9;
	height: 20px;
}
.nav__menu > li > a:hover {
	color: #00A4D3; }
.nav__menu > .active > a {
	color: #00A4D3; }
@media only screen and (min-width: 992px) {
	.nav__wrap {
		display: block !important;
		height: auto !important; }
	.nav__menu > li {
		display: inline-block;
		text-align: center; }
	.nav__dropdown-menu {
		position: absolute;
		left: -5px;
		top: 100%;
		z-index: 1000;
		min-width: 220px;
		width: 100%;
		text-align: left;
		padding: 20px 0;
		list-style: none;
		white-space: nowrap;
		background-color: #fff;
		box-shadow: 0 4px 20px rgba(154, 161, 171, 0.25);
		border-radius: 5px;
		background-clip: padding-box;
		display: block;
		visibility: hidden;
		opacity: 0;
		transition: all 0.2s ease-in-out; }
	.nav__dropdown-menu > li > a {
		color: #6A798C;
		padding: 5px 30px;
		display: block; }
	.nav__dropdown-menu > li > a:hover, .nav__dropdown-menu > li > a:focus {
		color: #00A4D3; }
	.nav__menu > .nav__dropdown > .nav__dropdown-menu:before {
		content: "";
		display: block;
		width: 14px;
		height: 14px;
		background-color: #fff;
		position: absolute;
		top: -7px;
		left: 1px;
		-webkit-transform: rotate(45deg);
		transform: rotate(45deg); }
	.nav__dropdown-menu.hide-dropdown {
		visibility: hidden !important;
		opacity: 0 !important; }
	.nav__dropdown-menu--right {
		right: 0; }
	.nav__dropdown:hover > .nav__dropdown-menu {
		opacity: 1;
		visibility: visible; }
	.nav__dropdown-menu .nav__dropdown-menu {
		left: 100%;
		top: -20px; }
	.nav__dropdown .nav__dropdown {
		position: relative; }
	.nav__dropdown .nav__dropdown > a:after {
		content: '\e804';
		font-family: 'ui-icons';
		font-size: 10px;
		position: absolute;
		right: 20px;
		top: 50%;
		-webkit-transform: translateY(-50%);
		transform: translateY(-50%); }
	.nav__dropdown-trigger {
		display: none; } }
.mobile body {
	cursor: pointer; }

/* Logo
-------------------------------------------------------*/
.logo {
	line-height: 1; }

/* Nav Flexbox
-------------------------------------------------------*/
header .flex-parent {
	align-items: center;
	max-width: 1056px;margin: auto; }
@media only screen and (max-width: 991px) {
	header .flex-parent {
		display: block;
		padding-top: 15px;
	} }

.nav--align-right {
	margin-left: auto; }

.nav--align-left {
	margin-right: auto; }

/* Nav Icon Toggle (mobile nav)
-------------------------------------------------------*/
.nav__icon-toggle {
	position: relative;
	padding: 9px 0 9px 10px;
	background-color: transparent;
	border: none;
	z-index: 50; }
@media only screen and (min-width: 992px) {
	.nav__icon-toggle {
		display: none; } }
.nav__icon-toggle-bar {
	background-color: #333B69;
	width: 18px;
	display: block;
	height: 2px;
	border-radius: 1px;
	margin-bottom: 4px; }
.nav__icon-toggle-bar:last-child {
	margin-bottom: 0; }
.nav__icon-toggle:focus .nav__icon-toggle-bar,
.nav__icon-toggle:hover .nav__icon-toggle-bar {
	background-color: #00A4D3; }
.nav__icon-toggle:focus {
	outline: none; }

/* Sticky Nav
-------------------------------------------------------*/
.nav--sticky {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	background-color: inherit;
	transition: all 0.2s ease-in-out; }

.nav__holder--sticky {
	margin-top: 0;
	background-color: #fff; }

/* Nav Button
-------------------------------------------------------*/
.nav__btn {
	background: #F8875F;
	/* Old browsers */
	/* FF3.6+ */
	/* Chrome,Safari4+ */
	/* Chrome10+,Safari5.1+ */
	/* Opera 11.10+ */
	/* IE10+ */
	background: linear-gradient(to right, #069EC6 0%, #00A4D3 100%);
	/* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#000000',GradientType=0 );
	/* IE6-9 */
	padding: 10px 17px;
	text-align: center; }
.nav__btn span {
	display: block; }
.nav__btn-text {
	font-size: 12px; }
.nav__btn-phone {
	font-size: 16px; }

/* Go to Top
-------------------------------------------------------*/
#back-to-top {
	display: block;
	z-index: 100;
	width: 34px;
	height: 34px;
	text-align: center;
	font-size: 12px;
	position: fixed;
	bottom: -34px;
	right: 20px;
	line-height: 34px;
	background-color: #F7F9FA;
	box-shadow: 0px 1px 4px 0px rgba(154, 161, 171, 0.4);
	border-radius: 50%;
	transition: all 0.2s ease-in-out;
	text-decoration: none; }
#back-to-top i {
	transition: all 0.2s ease-in-out; }
#back-to-top a {
	display: block;
	color: #333B69; }
#back-to-top.show {
	bottom: 20px; }
#back-to-top:hover {
	background-color: #00A4D3;
	bottom: 24px; }
#back-to-top:hover i {
	color: #fff; }

/*-------------------------------------------------------*/
/* Nav Mobile Styles
/*-------------------------------------------------------*/
@media only screen and (max-width: 991px) {
	.nav {
		min-height: 72px;
		margin-top: 0;
		background-color: #fff; }
	.nav__wrap {
		padding-left: 0; }
	.nav__header {
		height: 72px;
		display: flex;
		align-items: center; }
	.nav__menu {
		display: block;
	}
	.nav__menu li a {
		padding: 0;
		line-height: 46px;
		height: 46px;
		display: block;
		border-bottom: 1px solid #E8ECED; }
	.nav__dropdown-menu a {
		color: #6A798C; }
	.nav__dropdown-menu a:hover {
		color: #000; }
	.nav__dropdown-menu > li > a {
		padding-left: 10px; }
	.nav__dropdown-menu > li > ul > li > a {
		padding-left: 20px; }
	.nav__dropdown-trigger {
		display: block;
		width: 20px;
		height: 46px;
		line-height: 46px;
		text-align: center;
		position: absolute;
		right: 0;
		top: 0;
		z-index: 50;
		cursor: pointer; }
	.nav__dropdown-menu {
		display: none;
		width: 100% !important; }
	.nav__dropdown-trigger.active + .nav__dropdown-menu {
		display: block; }
	.logo-wrap {
		float: left; }
	.logo-wrap a {
		display: table-cell;
		vertical-align: middle;
		height: 72px; }
	.nav__btn {
		display: none;
		margin-bottom: 18px; }
	.sticky-on-mobile .navbar {
		box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
		background: #fff;
		position: fixed;
		top: 0;
		left: 0;
		right: 0; } }
