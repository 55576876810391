.check {
	width: 100px;
	height: 100px;
	background-color: #21C04E;
	border-radius: 100px;
	margin: 0 auto;
	align-items: center;
	justify-content: center;
	display: flex;
}
.check i {
	font-size: 52px;
	color: white;
}