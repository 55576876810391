#start h2 {
	font-family: "brandon_textregular" !important;
	text-transform: uppercase;
	margin-bottom: 16px;
}
#start h2 b {
	font-family: "brandon_textbold" !important;
	text-transform: uppercase;
}
#start h3 {
	text-transform: none;
}
#start h6 {
	margin-top: 12px;
	font-size: 18px;
	line-height: 20px;
}
#start img {
	max-width: 220px;
	margin-top: 20px;
	margin-bottom: 20px;
}
.ficoScore {
	font-family: "brandon_textregular";
	font-style: italic;
	text-transform: uppercase;
}