#landing .feature-b h3 {
	text-align: left !important;
}

#landing .request-form h2,
#landing .request-form p {
	text-align: center;
}

#landing .footerWrapper {
	background-color: #174072;
}

#landing h4 a {
	font-family: "brandon_text", sans-serif !important;
	/*color: #009ACD !important;*/
	margin-bottom: 0;
}

.imagen {
	background: none !important;
}

@media (min-width: 992px) {
	.imagen{
		background-image: url("../assets/landing/images/iphone.png") !important;
		background-repeat: no-repeat !important;
	}
}

#landing {
	background-color: #f4f6f8;
}

#landing iframe {
	width: 100% !important;
	min-height: 250px;
}

#landing .hero-title {
	font-size: 49px;
}

#landing h1 {
	font-size: 32px;
	line-height: 44px;
}

#landing h4 {
	font-size: 16px;
}

#landing .request-form h2 {
	font-size: 24px;
}
#landing .request-form button {
	height: 50px;
}

#landing .capital-title {
	font-family: "brandon_textblack", sans-serif !important;
	font-size: 16px;
	font-weight: 600;
	/*text-transform: none !important;*/
}

#landing .text-big a {
	font-family: "brandon_textbold", sans-serif !important;
	font-size: 20px;
	font-weight: 800;
	text-transform: none !important;
	color: #00a4d2;
}

#landing .text-big {
	font-size: 16px;
	margin-top: 0;
	margin-bottom: 0;
	line-height: 1.5;
}

#landing .feature-b {
	margin-bottom: 30px;
}

#landing .feature-content h4 {
	font-family: "brandon_textbold", sans-serif !important;
}

#landing .testimonial-text {
	font-family: 'Merriweather', serif !important;
	font-weight: 500;
	letter-spacing: -.8px !important;
	margin-bottom: 32px;
	font-size: 20px !important;
}

#landing .panel .panel-body {
	font-family: "brandon_textregular", sans-serif !important;

}

#landing input,
#landing select{
	font-size: 14px;
}