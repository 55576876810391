/* New Fonts Scheme */
@font-face {
	font-family: "Brandon";
	src: url("../assets/fonts/hvd_fonts_-_brandontext-regular-webfont.woff2") format('woff2'), url("../assets/fonts/hvd_fonts_-_brandontext-regular-webfont.woff") format('woff');
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-family: "Brandon";
	src: url("../assets/fonts/hvd_fonts_-_brandontext-bold-webfont.woff2") format('woff2'), url("../assets/fonts/hvd_fonts_-_brandontext-bold-webfont.woff") format('woff');
	font-weight: 700;
	font-style: normal;
}
@font-face {
	font-family: "Brandon";
	src: url("../assets/fonts/hvd_fonts_-_brandontext-black-webfont.woff2") format('woff2'), url("../assets/fonts/hvd_fonts_-_brandontext-black-webfont.woff") format('woff');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: "brandon_textbold";
	src: url("../assets/fonts/hvd_fonts_-_brandontext-bold-webfont.woff2") format('woff2'), url("../assets/fonts/hvd_fonts_-_brandontext-bold-webfont.woff") format('woff');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: "brandon_textregular";
	src: url("../assets/fonts/hvd_fonts_-_brandontext-regular-webfont.woff2") format('woff2'), url("../assets/fonts/hvd_fonts_-_brandontext-regular-webfont.woff") format('woff');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: "brandon_textmedium";
	src: url("../assets/fonts/hvd_fonts_-_brandontext-medium-webfont.woff2") format('woff2'), url("../assets/fonts/hvd_fonts_-_brandontext-medium-webfont.woff") format('woff');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: "brandon_textblack";
	src: url("../assets/fonts/hvd_fonts_-_brandontext-black-webfont.woff2") format('woff2'), url("../assets/fonts/hvd_fonts_-_brandontext-black-webfont.woff") format('woff');
	font-weight: normal;
	font-style: normal;
}
.innerMenu {
	background-color: #fff;
	padding-top: 12px;
	padding-bottom: 12px;
}
input,
select {
	font-size: 16px;
}
.description,
a,
btn,
h1,
h2,
h3,
h4,
h5,
h6,
label,
p {
	font-family: "brandon_textregular", sans-serif !important;
	letter-spacing: 0 !important;
	font-weight: normal !important;
}
h3 {
	font-family: "brandon_textmedium", sans-serif !important;
	font-weight: normal !important;
}
b {
	font-family: "brandon_textbold", sans-serif !important;
	font-weight: normal !important;
}