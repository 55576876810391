@charset "utf-8";
@font-face {
  font-family: 'Brandon-Regular';
  src: url('../fonts/brandon_regular.otf');

  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'Brandon-Bold';
  src: url('../fonts/brandon_bold.otf');

  font-weight: normal;
  font-style: normal;
}

.white{ color: #afe5f5; text-align: justify;}

.blanco{ color: #fff; font-size: 16px;}

.linea { border-top: 8px solid #fff;
width: 120px; margin-bottom: 30px; text-align: center;  }


.linea_azul { border-top: 8px solid #00a4d3;
width: 120px; margin-bottom: 30px; text-align: center;  }


/*body { font-size: 15px; font-family: 'Brandon-Regular', sans-serif; line-height: 1.4; color: #333; background-color: #f4f6f8; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; }*/
/*h1, h2, h3, h4, h5, h6 { margin: 0px 0px 10px 0px; color: #333; font-family: 'Brandon-Bold', sans-serif;}*/
/*h1 { font-size: 32px; line-height: 44px; letter-spacing: -1px; }*/
/*h2 { font-size: 24px; line-height: 32px; }*/
/*h3 { font-size: 20px; line-height: 28px; }*/
/*h4 { font-size: 16px; line-height: 1.6; }*/
/*h5 { font-size: 14px; }*/
/*h6 { font-size: 14px; }*/
/*p { margin: 0 0 26px; line-height: 1.6; font-weight: 400; }*/
/*ul, ol { }*/
/*ul li, ol li { line-height: 1.8 }*/
/*a { color: #00a4d2; text-decoration: none; -webkit-transition: all 0.3s ease-in-out; -moz-transition: all 0.3s ease-in-out; transition: all 0.3s ease-in-out; }*/
/*a:focus, a:hover { text-decoration: none; color: #15549a; }*/
/*strong { font-weight: 600; }*/
/*blockquote { padding: 10px 20px; margin: 0 0 0px; font-size: 21px; border-left: 5px solid #eff1f4; color: #414346; font-style: italic; line-height: 35px; font-family: 'Merriweather', serif; font-weight: 700; }*/
/*blockquote.alignright { max-width: 50%; }*/
/*blockquote.alignleft { max-width: 50%; }*/
/*p:last-child { margin-bottom: 0px; }*/
/*::selection { background: #ffecb3; color: #333; }*/
/**, *::before, *::after { transition: all .3s ease-in-out; }*/
/*.text-highlight { color: #ffecb3; }*/
/*.highlight { color: #00a4d2; }*/
/*.italic { }*/
/*.small-text { }*/
/*.listnone { list-style: none; margin: 0px 0px 20px 0px; padding: 0; }*/
/*.required { color: red }*/
/*.lead { color: #3c4d6b; line-height: 30px; font-size: 20px; }*/
/*.lead-strong { }*/
/*.section-title { }*/
/*.text-white { color: #fff; }*/
/*.title-white { color: #fff; }*/
/*.title { color: #3c4d6b; }*/
/*.text-bold { font-weight: 600; }*/
/*.text-black { color: #000000 !important; }*/
/*.text-light { color: #a1a4a8 !important; }*/
/*.text-dark { color: #414855 !important; }*/
/*.text-light-blue { color: #cce4ff; }*/
/*.border-primary { border-color: #15549a !important; }*/
/*.text-primary { color: #15549a !important; }*/
/*.border-default{border-color: #f51f8a !important;}*/
/*.border-top-default{border-top:3px solid #00a4d2;}*/
/*-----------------------------------
Space css class
-----------------------------------*/

/*----------------
margin space
-----------------*/

.mb0 { margin-bottom: 0px; }
.mb5 { margin-bottom: 5px; }
.mb10 { margin-bottom: 10px; }
.mb20 { margin-bottom: 20px; }
.mb30 { margin-bottom: 30px; }
.mb40 { margin-bottom: 40px; }
.mb60 { margin-bottom: 60px; }
.mb80 { margin-bottom: 80px; }
.mb100 { margin-bottom: 100px; }
.mt0 { margin-top: 0px; }
.mt10 { margin-top: 10px; }
.mt20 { margin-top: 20px; }
.mt30 { margin-top: 30px; }
.mt40 { margin-top: 40px; }
.mt60 { margin-top: 60px; }
.mt80 { margin-top: 80px; }
.mt100 { margin-top: 100px; }
.mr0 { margin-right: 0px; }
.mr10 { margin-right: 10px; }
.mr20 { margin-right: 10px; }
.ml0 { margin-left: 0px; }
.ml10 { margin-left: 10px; }
.ml20 { margin-left: 20px; }

/*----------------
less margin space from top
-----------------*/
.mt-20 { margin-top: -20px; }
.mt-40 { margin-top: -40px; }
.mt-60 { margin-top: -60px; }
.mt-80 { margin-top: -80px; }

/*---------------
Padding space
-----------------*/
.nopadding { padding: 0px; }
.nopr { padding-right: 0px; }
.nopl { padding-left: 0px; }
.pinside10 { padding: 10px; }
.pinside20 { padding: 20px; }
.pinside30 { padding: 30px; }
.pinside40 { padding: 40px; }
.pinside50 { padding: 50px; }
.pinside60 { padding: 60px; }
.pinside80 { padding: 80px; }
.pinside100 { padding: 100px; }
.pdt10 { padding-top: 10px; }
.pdt20 { padding-top: 20px; }
.pdt30 { padding-top: 30px; }
.pdt40 { padding-top: 40px; }
.pdt60 { padding-top: 60px; }
.pdt80 { padding-top: 80px; }
.pdb10 { padding-bottom: 10px; }
.pdb20 { padding-bottom: 20px; }
.pdb30 { padding-bottom: 30px; }
.pdb40 { padding-bottom: 40px; }
.pdb60 { padding-bottom: 60px; }
.pdb80 { padding-bottom: 80px; }
.pdl10 { padding-left: 10px; }
.pdl20 { padding-left: 20px; }
.pdl30 { padding-left: 30px; }
.pdl40 { padding-left: 40px; }
.pdl60 { padding-left: 60px; }
.pdl80 { padding-left: 80px; }
.pdl120 { padding-left: 120px; }
.pdr10 { padding-right: 10px; }
.pdr20 { padding-right: 20px; }
.pdr30 { padding-right: 30px; }
.pdr40 { padding-right: 40px; }
.pdr60 { padding-right: 60px; }
.pdr80 { padding-right: 80px; }
.x { }
.y { padding-top: 220px; }

/*---------------
Section Space
* Section space help to create top bottom space.
*
-----------------*/
.section-space20 { padding-top: 20px; padding-bottom: 20px; }
.section-space40 { padding-top: 40px; padding-bottom: 40px; }
.section-space60 { padding-top: 60px; padding-bottom: 60px; }
.section-space80 { padding-top: 80px; padding-bottom: 80px; }
.section-space100 { padding-top: 100px; padding-bottom: 100px; }
.section-space120 { padding-top: 120px; padding-bottom: 120px; }
.section-space140 { padding-top: 140px; padding-bottom: 140px; }

.azul {color: #00a4d3 !important;}

.imagen { background: url(../images/iphone.png)no-repeat; background-size: auto; background-position-x: left;  }
/*-----------------------------------------
Background & Block color
------------------------------------------*/
.bg-light { background-color: #fafcfd; }
.bg-primary { background-color: #eff1f4 !important; color: #778191; }
.bg-default { background-color: #15549a; color: #87c0ff; }
.bg-white { background-color: #fff; }
.outline { border: 1px solid #dee4ee; }
.link-title { }
.small-title { font-size: 14px; color: #778191; font-weight: 500; }
.big-title { font-size: 36px; font-weight: 600; color: #15549a; }
.capital-title { text-transform: uppercase; font-size: 16px; font-weight: 600; }
.bdr-btm { }
.green-text { }
.bg-boxshadow { -webkit-box-shadow: 0px 4px 16px 3px rgba(213, 221, 234, .5); -moz-box-shadow: 0px 4px 16px 3px rgba(213, 221, 234, .5); box-shadow: 0px 4px 16px 3px rgba(213, 221, 234, .5); }
.bg-gradient { position: relative;    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#1f2b70+0,1f2b70+47,943a9e+100&0.9+0,0.9+100 */ background: -moz-linear-gradient(-45deg, rgba(31, 43, 112, 1) 0%, rgba(31, 43, 112, 1) 47%, rgba(148, 58, 158, 1) 100%);    /* FF3.6-15 */ background: -webkit-linear-gradient(-45deg, rgba(31, 43, 112, 0.9) 0%, rgba(31, 43, 112, 0.9) 47%, rgba(148, 58, 158, 0.9) 100%);    /* Chrome10-25,Safari5.1-6 */ background: linear-gradient(135deg, rgba(31, 43, 112, 0.9) 0%, rgba(31, 43, 112, 0.9) 47%, rgba(148, 58, 158, 0.9) 100%);    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */ filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#e61f2b70', endColorstr='#e6943a9e', GradientType=1);    /* IE6-9 fallback on horizontal gradient */ }
.well-box { background-color: #fff; padding: 30px; border-radius: 4px; }
.bg-yellow-light { background-color: #fff9e0; }
.bg-pink { background-color: #f51f8a !important; }
.bg-dark { background-color: #4e5257 !important; }
.bg-dark-blue { background-color: #023975; }
.bg-light-blue { background-color: #15549a; }
.text-pink { color: #00a4d2; }

.card{border-color: #d3dbea; background-color: #f7fafd; border-radius: 3px; margin-bottom: 30px;}
.card-body{padding: 30px;}
.card-header{background-color: #f7fafd;}





/* Update 16 june 2017*/

/*-----------------------------------------
Buttons
------------------------------------------*/

/*-----------------------------------------
Bullet
------------------------------------------*/
.bullet { position: relative; padding-left: 25px; line-height: 32px; }
.bullet-arrow-circle-right li:before { font-family: 'FontAwesome'; display: block; position: absolute; left: 0; font-size: 15px; color: #919297; content: "\f18e"; }
.bullet-arrow-right li:before { font-family: 'FontAwesome'; display: block; position: absolute; left: 0; font-size: 15px; color: #747474; content: "\f101"; }
.bullet-check-circle li:before { font-family: 'FontAwesome'; display: block; position: absolute; left: 0; font-size: 15px; color: #15549a; content: "\f058"; }
.bullet-check-circle-default li:before { font-family: 'FontAwesome'; display: block; position: absolute; left: 0; font-size: 15px; color: #10a874; content: "\f05d"; padding-right: 4px; }

/* update 16 june 2017 */
.list-pdf-file li:before { font-family: 'FontAwesome'; display: block; position: absolute; left: 0; font-size: 15px; color: #ec4e10; content: "\f1c1"; padding-right: 4px; }
.list-pdf-file li a { color: #104b8c; text-decoration: underline; font-weight: 500; }
.list-pdf-file li a:hover { color: #00a4d2; }

/*-----------------------------------------
icon
------------------------------------------*/
.icon { }
.icon-1x { font-size: 24px; }

/* default icon size of font*/
.icon-2x { font-size: 48px; }
.icon-4x { font-size: 68px; }
.icon-6x { font-size: 88px; }
.icon-8x { font-size: 108px; }
.icon-default { color: #00a4d2; }
.icon-primary { color: #15549a; }
.icon-secondary { color: #bcbcbc; }
.dark-line { border-top: 2px solid #fff; }

/*-----------------------------------------
index-1
------------------------------------------*/
.mail-text { border-right: 2px solid #1b5ca5; }
.rate-table { background-color: #fff; border-top: 1px solid #dee7f2; border-bottom: 1px solid #dee7f2; margin-top: -21px; }
.loan-rate { font-size: 32px; color: #414855; margin-bottom: 0px; font-weight: 600; line-height: 1; }
.rate-counter-block { border-right: 1px solid #dee7f2; padding-top: 20px; padding-bottom: 20px; }
.rate-counter-block:first-child { }
.rate-icon { float: left; padding-top: 5px; padding-right: 20px; padding-left: 20px; }
.rate-box { }
.rate-title { text-transform: uppercase; font-size: 14px; color: #778191; }
.cta { background-size: cover; margin: 0; min-height: 410px; border: none; padding: 100px 0px; background: linear-gradient(rgba(16, 75, 149, 0.8), rgba(16, 75, 149, 0.8)), rgba(16, 75, 149, 0.8) url(../images/cta-img.jpg) no-repeat center; }
.testimonial-img-1 { }
.testimonial-name-1 { color: #15549a; font-size: 14px; text-transform: uppercase; font-weight: 500; margin-bottom: 0px; }


/*---------------------------------------------------
    Card Landing Page
-----------------------------------------------------*/
.header-wide { padding-top: 10px; padding-bottom: 10px; }
.card-hero-section { padding-top: 100px; padding-bottom: 100px; background: rgba(19, 88, 144, 1); background: -moz-linear-gradient(-45deg, rgba(19, 88, 144, 1) 0%, rgba(19, 88, 144, 1) 37%, rgba(59, 64, 160, 1) 57%, rgba(59, 64, 160, 1) 77%, rgba(59, 64, 160, 1) 100%); background: -webkit-gradient(left top, right bottom, color-stop(0%, rgba(19, 88, 144, 1)), color-stop(37%, rgba(19, 88, 144, 1)), color-stop(57%, rgba(59, 64, 160, 1)), color-stop(77%, rgba(59, 64, 160, 1)), color-stop(100%, rgba(59, 64, 160, 1))); background: -webkit-linear-gradient(-45deg, rgba(19, 88, 144, 1) 0%, rgba(19, 88, 144, 1) 37%, rgba(59, 64, 160, 1) 57%, rgba(59, 64, 160, 1) 77%, rgba(59, 64, 160, 1) 100%); background: -o-linear-gradient(-45deg, rgba(19, 88, 144, 1) 0%, rgba(19, 88, 144, 1) 37%, rgba(59, 64, 160, 1) 57%, rgba(59, 64, 160, 1) 77%, rgba(59, 64, 160, 1) 100%); background: -ms-linear-gradient(-45deg, rgba(19, 88, 144, 1) 0%, rgba(19, 88, 144, 1) 37%, rgba(59, 64, 160, 1) 57%, rgba(59, 64, 160, 1) 77%, rgba(59, 64, 160, 1) 100%); background: linear-gradient(135deg, rgba(19, 88, 144, 1) 0%, rgba(19, 88, 144, 1) 37%, rgba(59, 64, 160, 1) 57%, rgba(59, 64, 160, 1) 77%, rgba(59, 64, 160, 1) 100%); filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#135890', endColorstr='#3b40a0', GradientType=1); }
.card-hero-block { }
.card-benefit-tabs .st-tabs .tab-content { padding: 0px; border: none; background-color: transparent; margin-top: 30px; }
.card-benefit-tabs .nav-tabs { border-bottom: 0px; }
.card-benefit-tabs .st-tabs .nav-link { display: block; padding: 16px 49px; border: 1px solid #f6f9ff; border-radius: 5px; background-color: #f6f9ff; margin-bottom: -1px; margin-right: 10px; }
.card-benefit-tabs .st-tabs a { font-size: 14px; font-style: initial; text-transform: capitalize; font-weight: 500; color: #7e93ae; }
.card-benefit-tabs .st-tabs .nav-tabs .nav-item.show .nav-link, .card-benefit-tabs .st-tabs .nav-tabs .nav-link.active { color: #ffffff; background-color: #15549a; border-color: #15549a; }
.card-rate-info .list-group-item { position: relative; display: block; padding: 19px 30px; margin-bottom: 2px; background-color: #e8f0f9; border: transparent; font-size: 14px; border-radius: 0px; }

/*---------------------------------------------------
    Card Landing Page
-----------------------------------------------------*/
.header-standard { }
.header-standard.header { padding-bottom: 0px; padding-top: 0px; }
.top-header { padding-top: 22px; padding-bottom: 22px; }
.quick-info { float: right; font-size: 16px; color: #414855; font-weight: 600; padding-top: 9px; }
.header-standard .search-btn { border-radius: 2px; width: 46px; height: 42px; padding: 10px 0px 0px 0px; margin-top: 0px; color: #ffffff; background-color: #15549a; display: inline-block; }
.header-standard #navigation { float: inherit; margin-top: -1px; }
.header-standard #navigation>ul>li>a { padding: 22px 35px; color: #ffffff; font-weight: 400; }
.slider-second .slider-img { background: none; }
.slider-second#slider { margin-bottom: -1px; }
.slider-second#slider .owl-pagination { display: none; }
.overlay-section { margin-top: -60px; }
.hover-tabs { }
.hover-tabs.st-tabs .nav-link { display: block; padding: 16px 68px 16px 0px; border: none; border-radius: 0px; background-color: transparent; margin-bottom: 10px; }
.hover-tabs.st-tabs .tab-content { padding: 30px; border: 1px solid #ffffff; background-color: #fff; margin-top: -1px; border-radius: 5px; }
.hover-tabs.st-tabs a { font-size: 16px; font-style: initial; text-transform: capitalize; font-weight: 500; color: #15549a; }
.post-caption-block { position: relative; }
.post-caption-img { }
.post-caption-img img { border-radius: 3px; }
.post-caption-content { position: absolute; bottom: 0px; padding: 27px; }
.post-caption-block .meta { text-transform: capitalize; }
/*.cta-section-app { background: url(../images/cta-img-4.jpg)no-repeat; background-size: cover; background-position-x: 50%; }*/


/*--------------------------------
testimonial
---------------------------------*/
.testimonial-block { position: relative; }
.testimonial-text { font-family: 'Merriweather', serif; color: #414855; font-style: italic; line-height: 32px; }
.testimonial-autor { padding-top: 15px; padding-bottom: 12px; }
.testimonial-name { color: #fff; line-height: 1; margin-bottom: 5px; }
.testimonial-autor-box { }
.testimonial-title { color: #00a4d2; margin-bottom: 0px; }
.testimonial-img { float: left; padding-right: 29px; }
.testimonial-meta { font-size: 12px; }
.testimonial-simple { }
.testimonial-name-inverse { color: #15549a; text-transform: uppercase; font-size: 14px; font-weight: 600; }
.client-rating { margin-bottom: 10px; }
.rate-done { color: #f9b218; letter-spacing: 3px; }
.rate-remain { color: #c7ced5; }

/*--------------------------------
Pagination
---------------------------------*/
.st-pagination { }
.st-pagination .pagination>li>a, .pagination>li>span { position: relative; float: left; margin-left: -1px; line-height: 1.42857143; color: #9a9fab; text-decoration: none; font-size: 12px; border: 2px solid #c3c7d1; font-weight: 600; border-radius: 4px; text-transform: uppercase; margin-right: 3px; margin-bottom: 5px; background-color: #fff; padding: 8px 13px; }
.st-pagination .pagination>li>a:focus, .st-pagination .pagination>li>a:hover, .st-pagination .pagination>li>span:focus, .st-pagination .pagination>li>span:hover { z-index: 2; color: #fff; background-color: #00a4d2; border-color: #00a4d2; border-radius: 4px; }
.st-pagination .pagination>li:first-child>a, .st-pagination .pagination>li:first-child>span { margin-left: 0; border-radius: 4px; }
.st-pagination .pagination>li:last-child>a, .st-pagination .pagination>li:last-child>span { border-radius: 4px; }
.st-pagination .pagination>.active>a, .st-pagination .pagination>.active>a:focus, .st-pagination .pagination>.active>a:hover, .st-pagination .pagination>.active>span, .st-pagination .pagination>.active>span:focus, .st-pagination .pagination>.active>span:hover { z-index: 3; color: #fff; cursor: default; background-color: #00a4d2; border-color: #00a4d2; border-radius: 4px; }
.st-accordion { }
.st-accordion .sign { color: #3c4d6b; padding: 0 2px; position: absolute; left: 0px; top: 5px; margin-left: -20px; font-size: 14px; }
.st-accordion .accordion .panel-heading.active .sign { color: #95cd40; }
.st-accordion .panel-title { margin-top: 0; margin-bottom: 0; font-size: 18px; color: inherit; position: relative; color: #3c4d6b; }
.sign { color: #084988; padding: 0 2px; position: absolute; right: -10px; top: 6px; }
.st-accordion .panel-heading.active .sign { color: #00a4d2; }
.st-accordion .panel { margin-bottom: 20px; background-color: #fff; border: 1px solid transparent; border-radius: 0px; -webkit-box-shadow: none; box-shadow: none; }
.st-accordion .panel-default { border-color: transparent; border-bottom: 1px solid #e0e7ea; }
.st-accordion .panel-group .panel { margin-bottom: 10px; border-radius: 0px; -webkit-box-shadow: 0px 0px 0px 0px rgba(234, 234, 234, 1); -moz-box-shadow: 0px 0px 0px 0px rgba(234, 234, 234, 1); box-shadow: 0px 0px 0px 0px rgba(234, 234, 234, 1); }
.st-accordion .panel-default>.panel-heading { color: #323734; background-color: #fff; border-bottom: 1px solid transparent; padding: 10px 0px 10px 30px; }
.st-accordion .panel-group .panel-heading+.panel-collapse>.list-group, .st-accordion .panel-group .panel-heading+.panel-collapse>.panel-body { }
.st-accordion .panel-body { padding: 30px; }
.st-accordion .panel-group .panel-heading+.panel-collapse>.list-group, .panel-group .panel-heading+.panel-collapse>.panel-body { border-top: 1px solid transparent; }

/* ============================================================
GLOBAL
============================================================ */

.effects { }
.effects .img { position: relative; }
.effects .img:nth-child(n) { }
.effects .img:first-child { margin-bottom: 20px; }
.effects .img:last-child { margin-right: 0; }
.effects .img img { display: block; margin: 0; padding: 0; max-width: 100%; height: auto; }
.overlay { display: block; position: absolute; z-index: 20; background: rgba(246, 96, 171, 0.8); overflow: hidden; -webkit-transition: all 0.5s; -moz-transition: all 0.5s; -o-transition: all 0.5s; transition: all 0.5s; }
a.close-overlay { display: block; position: absolute; top: 0; right: 0; z-index: 100; width: 45px; height: 45px; font-size: 20px; font-weight: 700; color: #fff; line-height: 45px; text-align: center; background-color: #de4526; cursor: pointer; }
a.close-overlay.hidden { display: none; }
a.expand { display: block; position: absolute; z-index: 100; width: 60px; height: 60px; border: solid 5px #fff; text-align: center; color: #fff; line-height: 50px; font-weight: 700; font-size: 30px; -webkit-border-radius: 30px; -moz-border-radius: 30px; -ms-border-radius: 30px; -o-border-radius: 30px; border-radius: 30px; }

/* ============================================================
EFFECT 6 - ICON BOUNCE IN
============================================================ */
#effect-6 .overlay { top: 0; bottom: 0; left: 0; right: 0; opacity: 0; }
#effect-6 .overlay a.expand { left: 0; right: 0; top: 0; margin: 0 auto; opacity: 0; -webkit-transition: all 0.5s; -moz-transition: all 0.5s; -o-transition: all 0.5s; transition: all 0.5s; }
#effect-6 .img.hover .overlay { opacity: 1; }
#effect-6 .img.hover .overlay a.expand { top: 50%; margin-top: -30px; opacity: 1; }

/*-----------------------------------------
Landing Page Template
------------------------------------------*/
.hero { position: relative; padding-top: 60px; padding-bottom: 100px; background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), rgba(0, 0, 0, 0.2) url(../images/landing.jpg) no-repeat center; background-size: cover; }
.hero-home-loan { position: relative; padding-top: 60px; padding-bottom: 100px; background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), rgba(0, 0, 0, 0.2) url(../images/home-loan-lp.jpg) no-repeat center; background-size: cover; }
.hero-title { font-size: 49px; line-height: 52px; color: #fff;  letter-spacing: -1px; margin-bottom: 15px; }
.hero-sub { font-size: 32px; line-height: 32px; color: #fff; letter-spacing: -1px;  }
.hero-captions { padding-top: 0px; }
.hero-text { color: #fff; }
.request-form { background-color: #fff; padding: 30px; border-radius: 0px; }
.form-title { color: #104b8c; font-size: 30px; }
.price-rate { margin-top: 30px; }
.price-big { font-size: 40px; color: #fff; font-weight: 800; }
.price-rate small { color: #fff; text-transform: uppercase; }
.new-price, .old-price { margin-bottom: 30px; }

/*--------------- Features block class --------------*/
.circle-outline-icon { font-size: 36px; height: 80px; width: 80px; border: 2px solid #85b6ee; border-radius: 100%; padding: 18px 19px; margin-bottom: 30px; color: #15549a; display: block; line-height: 1.2; text-align: center; }
.feature-card .feature-content { }
.feature { }
.feature-icon { }
.feature .feature-icon { margin-bottom: 20px; }

/*-------- feature icon left --------*/
.feature-left { }
.feature-left .feature-icon { float: left; color: #07ec07; font-size: 35px; line-height: 1; }

.feature-left .feature-icon-beto { float: left; color: #00a5d2; font-size: 30px; line-height: 1; }
.feature-left .feature-content { padding-left: 40px; }
.feature-content { }
