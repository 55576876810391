/*TODO: When fully migrating to Antd remove helpers */

input,
select,
textarea {
	/*height: 46px;*/
	padding: 12px;
	margin: 0 !important;
	border: transparent;
	border-radius: 0;
	background-color: #eee8;
}

.ant-input-affix-wrapper {
	padding: 15px !important;
	background-color: #f6f6f6;
}

.ant-select-selector {
	height: 52px !important;
	/*padding-top: 10px !important;*/
	padding-left: 15px !important;
}

input:focus {
	background-color: #f6f6f6;
}

input::placeholder {
	color: #bbb !important;
}

/* Custom Design for Radio Buttons */
.ant-radio-group-solid label {
	font-weight: bold !important;
	padding: 0 65px;
	line-height: 48px;
	font-size: 12px;
	height: 50px;
}

@media screen and (max-width: 480px) {
	.ant-radio-group-solid label {
		padding: 0 30px;
	}
}

.ant-radio-group-solid label:first-child {
	border-top-left-radius: 30px;
	border-bottom-left-radius: 30px;
}

.ant-radio-group-solid label:last-child {
	border-top-right-radius: 30px;
	border-bottom-right-radius: 30px;
}
