#formSections {
	position: relative;
	height: 130px;
}
#formSection0 {
	width: 100%;
	left: 0;
	position: absolute;
}
#formSection1,
#formSection10,
#formSection2,
#formSection3,
#formSection4,
#formSection5,
#formSection6 {
	opacity: 0;
	width: 100%;
	left: 100%;
	position: absolute;
}